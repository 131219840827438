<template>
  <div>
    <trieste-table
      cache-table-filters
      default-sort-prop="email"
      item-type="email brands"
      ref="table"
      title="Email brands" :end-point="'/v1/companies/' + $store.state.company.company.id + '/email-brands'"
      :fields="fields"
      :filters="filters"
      :show-title="false"
      saved-table-sort-name="emailbrands-index"
      :activeTabName="activeTab"
    >
    <template v-slot:header-right>
      <el-button type="success" size="medium" icon="el-icon-view" v-if="$store.getters['auth/hasPermission']('emailaccount.create')" @click="showCreateDialog">Create</el-button>
    </template>
      <template v-slot:actions-slot="props">
        <el-button
          size="small"
          icon="el-icon-edit"
          type="primary"
          @click="showEditDialog(props.row)"
          v-if="$store.getters['auth/hasPermission']('emailaccount.update')"
          >
          Edit
        </el-button>
      </template>

    </trieste-table>
    <create-edit-dialog :show.sync="showCreateEdit" ref="createEditDialog" @success="refresh" />
  </div>
</template>
<script>
import CreateEditDialog from '@/components/emailBranding/CreateEdit';
export default {
  components: { CreateEditDialog },
  props: {
    activeTab: String
  },
  data() {
    return {
      fields: [
        {
          sortField: 'name',
          name: 'name',
          title: 'Name'
        },
        {
          sortField: 'domain',
          name: 'domain',
          title: 'domain'
        },
        {
          sortField: 'active',
          name: 'active',
          title: 'Active',
          booleanField: true
        },
        {
          sortField: 'created_at',
          name: 'created_at',
          title: 'Created at',
          dateField: true
        },
        {
          title: '',
          name: 'actions-slot',
          fixed: 'right',
          width: '110px'
        },
      ],
      filters: [
        {
          label: 'Name',
          name: 'name'
        },
        {
          label: 'domain',
          name: 'domain'
        },
        {
          label: 'Created at',
          name: 'created_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Active',
          name: 'active',
          type: 'select',
          options: [
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]
        }
      ],
      showCreateEdit: false
    }
  },
  methods: {
    showCreateDialog() {
      this.$refs.createEditDialog.resetFields();
      this.showCreateEdit = true;
    },
    showEditDialog(row) {
     this.$refs.createEditDialog.setFields(row);
     this.showCreateEdit = true;
    },
    refresh() {
      this.$refs.table.getData()
    }
  }
}
</script>
