<template>
  <div>
    <div v-if="false">
      <el-alert type="warning" v-if="email_testing_status.TESTING > 0 || email_testing_status.TO_TEST > 0">
        {{ email_testing_status.TESTING + email_testing_status.TO_TEST }} are currently being tested. {{ email_testing_status.TESTED }} have completed testing.
      </el-alert>
    </div>
    <div class="page-title">
      <h1>
        Email Accounts
      </h1>
    </div>
    <el-tabs class="custom-tabs-v1" @tab-click="tabsChanged" v-model="activeTabName" ref="tabs">
      <el-tab-pane label="Email Accounts"  name="accounts">
             <span slot="label">
               <a href="?tab=accounts" @click.prevent>Email Accounts</a>
             </span>
        <email-account-list :activeTab="'emailaccounts-index'" />
      </el-tab-pane>
      <el-tab-pane label="Email Brands" name="brands">
             <span slot="label">
               <a href="?tab=brands" @click.prevent>Email Brands</a>
             </span>
        <email-branding-tab :activeTab="'emailbrands-index'" />
      </el-tab-pane>
      <el-tab-pane label="Users"  name="users">
             <span slot="label">
               <a href="?tab=users" @click.prevent>Users</a>
             </span>
        <user-list :activeTab="'emailaccounts-users-index'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import tabPosition from '@/components/mixins/tab_position';
import EmailAccountList from './tabs/EmailAccounts';
import UserList from './tabs/Users';
import EmailBrandingTab from '@/components/views/emailAccounts/branding';
export default {
  name: 'ViewClient',
  mixins: [tabPosition],
  components: { EmailAccountList, UserList, EmailBrandingTab },
  metaInfo() {
    return {
      title: 'Email accounts'
    }
  },
  data() {
    return {
      activeTabName: 'accounts',
      email_testing_status: {
        TO_TEST: 0,
        TESTING: 0,
        TESTED: 0
      }
    }
  },
  mounted() {
    if(!this.$store.getters['auth/hasPermission']('emailaccount.read')) {
      this.$message.error('You do not have permissions to view email accounts.');
      this.$router.push('/');
    } else {
      this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
        this.$store.commit('global/setBreadcrumbs', [
          {
            path: '/',
            label: company.name
          },
          {
            label: 'Email accounts'
          }
        ])
      })
      // this.getEmailTestingStatus()
    }
  },
  methods: {
    getEmailTestingStatus () {
      this.$http.get('/v1/companies/' + this.$store.state.company.company.id + '/email-accounts/test-status')
      .then(r => {
        this.email_testing_status = Object.assign({}, this.email_testing_status, r.data)
      }).catch((e) => this.$message.error('Failed to get the email status: ' + e.toString()))
    }
  }
}
</script>
