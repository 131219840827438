<template>
  <div v-loading="downloading || !ready" style="min-height: 300px">

    <trieste-table
      cache-table-filters
      @filters-change="onActiveFiltersChange"
      :title="customTitle || (activeOnly ? 'leads' : 'links')"
      :item-type="customItemType || (activeOnly ? 'leads' : 'links')"
      :end-point="`/v1/companies/${$store.state.company.company.id}/links`"
      :fields="getFields"
      :params="getParams"
      :show-rpp-select="showRppSelect"
      :add-page-to-url="true"
      :default-sort-prop="defaultSortProp"
      default-sort-direction="descending"
      ref="table"
      :allow-selection="shouldAllowMultipleSelect"
      :selected-rows.sync="rowsSelected"
      :filters="getFilters"
      :show-title="showTitle"
      :default-filter-data="defaultFilters"
      :required-field-names="['lead_status']"
      :saved-table-sort-name="getTableSaveName"
      v-if="ready"
      @on-load="onLoad"
      :show-pagination-options="showPaginationOptions"
      :pagination-values="paginationValues"
    >
      <template v-slot:header-right v-if="exportMode || allowMassUpdate || (activeOnly && allowMassLeadUpdate) || $scopedSlots['header']">
        <div class="text-right flex flex-row justify-end">
          <slot name="header"></slot>
          <div style="width: 100px"  v-if="exportMode">
          <el-dropdown :size="showTitle? 'small' : 'medium'" split-button type="primary" @click="download" @command="download">
            <i class="el-icon-download el-icon--left"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="csv">CSV</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          </div>
          <div style="width: 180px" v-if="allowMassUpdate && rowsSelected.length">
            <el-dropdown :size="showTitle? 'small' : 'medium'" split-button type="primary" @command="setUpMassUpdate">
              <i class="el-icon-download el-icon--left"></i>Mass update
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="accepted">Accept</el-dropdown-item>
                <el-dropdown-item command="deferred">Defer</el-dropdown-item>
                <el-dropdown-item command="declined_parked">Park</el-dropdown-item>
                <el-dropdown-item command="declined">Reject</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-if="activeOnly && allowMassLeadUpdate && $store.getters['auth/hasPermission']('lead.bulk.update')" class="ml-2">
            <el-button type="primary" size="medium" @click="bulkUpdateForm.show = true">Bulk Update</el-button>
          </div>
        </div>
      </template>
      <template v-slot:url-slot="props">
        <div class="flex" v-if="!includeBillingHistory">
          <a target="_blank" :href="getOutboundSafeUrl(props.row.from_url)" class="">{{ props.row.from_url }}</a>
        </div>
      <div  v-else>
        <el-popover
          placement="right"
          width="400"
          trigger="hover">
          <div style="max-height: 500px; overflow-y: auto; margin: -12px; padding: 1em;">
            <div class="flex flex-row pb-2 border-b border-solid border-gray-200">
              <span class="text-sm mr-1 block">
                <i class="el-icon-link"></i>
              </span>
              <div class="flex flex-col">
              <span class="font-bold text-blue-800">
                <a :href="getOutboundSafeUrl(props.row.from_url)" target="_blank">{{ props.row.from_url }}</a>
              </span>
              <span class="font-bold text-gray-700">
                {{ (props.row.billing_status || 'Pending').replace(/_/g, ' ').toLowerCase() | capitalize }}
              </span>
              </div>
            </div>
            <div v-if="props.row.BillingStateNotes && props.row.BillingStateNotes.length">
              <div class="flex flex-row mt-2 pb-2 border-b border-solid border-gray-200">
                <span class="font-bold text-xs">LinkDev Notes ({{ props.row.BillingStateNotes.length }})</span>
              </div>
              <div>
                <ul class="">
                  <li v-for="(i, k) in props.row.BillingStateNotes" :key="k"  class="my-2 py-2 border-b border-solid border-gray-200">
                    <div v-html="i.body.replace(/\n/g, '<br />')"></div>
                    <span class="block text-xs text-gray-600 mt-1">{{ i.owner ? `${i.owner?.firstname} ${i.owner?.lastname}` : 'N/A' }} | {{ i.created_at | momentLocal("ddd MMMM DD YYYY")  }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex flex-row mt-2" v-if="props.row.BilledStateChain && props.row.BilledStateChain.length">
              <span class="font-bold text-xs">State Changes ({{ props.row.BilledStateChain.length }})</span>
            </div>
            <div v-for="(s, i) in props.row.BilledStateChain" :key="'billing' + i" class="my-2 pt-2 border-t border-solid border-gray-200">
                <span class="block text-xs font-bold text-blue-800">
                  {{ s.from.toLowerCase().replace(/_/g, ' ') | capitalize }} -> {{ s.to.toLowerCase().replace(/_/g, ' ') | capitalize }}
                </span>
                <span class="block text-xs font-medium">{{ s.note }}</span>
                <span class="block text-xs text-gray-600 mt-1">{{ s.creator ? `${s.creator?.firstname} ${s.creator?.lastname}` : 'N/A' }} | {{ s.created_at | momentLocal("ddd MMMM DD YYYY")  }}</span>
            </div>
          </div>
          <div slot="reference">
            <div class="flex">
              <logo
                :size="20"
                class="mr-2"
                v-model="props.row.from_url" v-if="false" />
              <a target="_blank" :href="getOutboundSafeUrl(props.row.from_url)" class="w-full font-bold break-all">{{ props.row.from_url }}</a>
              <div class="flex flex-row items-center">
                <div class="text-base">
                  <i class="el-icon-circle-check text-blue-800" v-if="props.row.billing_status === 'APPROVED'"></i>
                  <i class="el-icon-error text-red-600" v-else-if="props.row.billing_status === 'REJECTED' || props.row.billing_status === 'DEAD'"></i>
                  <i class="el-icon-remove text-blue-600" v-else-if="props.row.billing_status === 'POSTPONED'"></i>
                  <i class="el-icon-warning text-yellow-600" v-else-if="props.row.billing_status === 'IN_CORRECTION'"></i>
                  <i class="el-icon-money text-green-600" v-else-if="props.row.billing_status === 'BILLED'"></i>
                  <i class="el-icon-question text-gray-600" v-else></i>
                </div>
                <div v-if="props.row.BillingStateNotes && props.row.BillingStateNotes.length" class="flex flex-row items-center">
                  <i class="el-icon-s-comment text-base"></i>({{ props.row.BillingStateNotes.length }})
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      </template>

      <template v-slot:id="props">
        <slot name="id" :row="props.row">
          <a :href="`/company/${$store.state.company.company.id}/${props.row.lead_status !== 'accepted' ? 'links' : 'leads'}/${props.row.id}`" class="font-bold">
            {{props.row.id}}
          </a>
        </slot>
      </template>

      <template v-slot:status-slot="props">
        {{  props.row.status | prettyLinkStatus }}
      </template>

      <template v-slot:type-slot="props">
        {{  props.row.link_type | prettyLinkType }}
      </template>

      <template v-slot:owner-slot="props">
        {{  props.row.Owner  }}
      </template>

      <template v-slot:anchor-url="props">
        <span class="text-gray-400 text-xs" v-if="!props?.row?.link_monitoring?.target_link">N/A</span>
        <el-popover
          v-else
          placement="right"
          width="400"
          trigger="hover"
        >
          <div>
            <span class="font-bold text-blue-800">{{ props?.row?.link_monitoring?.target_link }}</span>
          </div>
          <div slot="reference">
            <div class="flex">
              <logo
                :size="20"
                class="mr-2"
                v-model="props.row.link_monitoring.target_link" v-if="false" />
              <a target="_blank" :href="getOutboundSafeUrl(props.row.link_monitoring.target_link)" class="w-full font-bold">{{ stripDomainFromUrl(props.row.link_monitoring.target_link) }}</a>
            </div>
          </div>
        </el-popover>
      </template>

      <template v-slot:target-slot="props">
        {{  props.row.SiteTargetName  }}
      </template>

      <template v-slot:firstname-form-slot="props">
        <el-form :model="props.row" label-width="0px" ref="quickContactForm">
          <el-form-item prop="contact_firstname"><el-input v-model="props.row.contact_firstname" /></el-form-item>
        </el-form>
      </template>

      <template v-slot:lastname-form-slot="props">
        <el-form :model="props.row" label-width="0px" ref="quickContactForm">
          <el-form-item prop="contact_lastname"><el-input v-model="props.row.contact_lastname" /></el-form-item>
        </el-form>
      </template>

      <template v-slot:paypal-account-slot="props">
        {{ props.row.paypal_account }}
      </template>

      <template v-slot:email-form-slot="props">
        <el-form :model="props.row" label-width="0px" ref="quickContactForm">
          <el-form-item prop="contact_email"><el-input v-model="props.row.contact_email" /></el-form-item>
        </el-form>
      </template>

      <template v-slot:actions-slot="props">
        <slot name="custom-actions" :row="props.row">
          <a
            v-if="(activeOnly && $store.getters['auth/hasPermission']('lead.read')) || (!activeOnly && $store.getters['auth/hasPermission']('link.read'))"
            :href="`/company/${$store.state.company.company.id}/${props.row.lead_status !== 'accepted' ? 'links' : 'leads'}/${props.row.id}`"
          >
          <el-button
            size="small"
            icon="el-icon-view"
            type="primary">
            View
          </el-button>
        </a>
        </slot>
      </template>
      <template v-for="(t, i) in getCustomSlots()" v-slot:[i]="props">
        <slot :name="i" :row="props.row"></slot>
      </template>

    </trieste-table>
    <trieste-dialog title="Bulk Update" :show.sync="bulkUpdateForm.show">
      <div class="p-4" v-loading="bulkUpdateForm.loading">
        <el-form label-position="left" label-width="120px">
          <el-form-item label="status">
            <el-select
              filterable
              v-model="bulkUpdateForm.form.status"
              class="w-full"
            >
              <el-option :value="null" label="Don't change" />
              <el-option
                v-for="(o, i) in $store.state.link.statuses"
                :key="i"
                :value="o.value"
                :label="o.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Type">
            <el-select
              filterable
              v-model="bulkUpdateForm.form.type"
              class="w-full"
            >
              <el-option :value="null" label="Don't change" />
              <el-option
                v-for="(o, i) in $store.state.link.types"
                :key="i"
                :value="o.value"
                :label="o.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="User">
            <el-select
              filterable
              v-model="bulkUpdateForm.form.owner"
              class="w-full">
              <el-option :value="null" label="Don't change" />
              <el-option v-for="(user, i) in company_users" :key="i" :value="user.id" :label="`${user.firstname} ${user.lastname}`" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template v-slot:footer-right>
        <el-button @click="bulkUpdateForm.show = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button
          v-if="bulkUpdateForm.form.owner || bulkUpdateForm.form.type || bulkUpdateForm.form.status"
          type="success"
          icon="el-icon-edit-outline" @click="bulkUpdateLeads" size="small">Update</el-button>
      </template>
    </trieste-dialog>

    <trieste-dialog title="Bulk Update" :show.sync="show_mass_update_confirm">
      <div class="p-4" v-loading="downloading" v-if="show_mass_update_confirm">
        Are you sure you want to {{getMassUpdateType}} {{ rowsSelected.length }} links?
        <div v-if="mass_update_contact.length">
        <el-form :model="mass_update_contact[key]" ref="mass_update_contact_form" label-width="120px" label-position="left"
                 v-show="mass_update_type === 'accepted'" :rules="mass_update_rules" class="mt-4" v-for="(i, key) in rowsSelected" :key="key">
          <el-form-item label="Link ID/URL">
            {{ i.id }}<br/>
            {{ i.from_url }}
          </el-form-item>
          <el-form-item label="First name" prop="firstname">
            <el-input v-model="mass_update_contact[key].firstname" />
          </el-form-item>
          <el-form-item label="last name" prop="lastname">
            <el-input v-model="mass_update_contact[key].lastname" />
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input v-model="mass_update_contact[key].email" />
          </el-form-item>
          <el-form-item label-width="0px">
            <div class="border-b w-full border-gray-300" v-if="(key + 1) !== rowsSelected.length"></div>
          </el-form-item>
          </el-form>
        </div>
      </div>
      <template v-slot:footer-right>
        <el-button @click="show_mass_update_confirm = false" icon="el-icon-close" type="danger" size="small">Cancel</el-button>
        <el-button
          type="success"
          icon="el-icon-edit-outline" @click="massUpdate" size="small">Confirm</el-button>
      </template>
    </trieste-dialog>
  </div>
</template>

<script>
  const FileDownload = require('js-file-download');
  const linkFailedReason = require('@/utils/link/reject_reasons.json')
  export default {
    name: 'LinkIndex',
    props: {
      activeOnly: {
        type: Boolean,
        default: false
      },
      status: {
        type: String,
        default: null
      },
      customTitle: {
        type: String,
        default: null
      },
      customItemType: {
        type: String,
        default: null
      },
      isBilled: {
        type: Boolean,
        default: null
      },
      billingStatus: {
        type: String,
        default: null
      },
      noActions: {
        type: Boolean,
        default: false
      },
      actionsFieldWidth: {
        type: [Number, String],
        default: '110px'
      },
      allowSelection: {
        type: Boolean,
        default: false
      },
      showTitle: {
        type: Boolean,
        default: true
      },
      allowMassUpdate: {
        type: Boolean,
        default: false
      },
      allowCreateContactOnMassApprove: {
        type: Boolean,
        default: false
      },
      includeExchangePrices: {
        type: Boolean,
        default: false
      },
      includeBillingHistory: {
        type: Boolean,
        default: false
      },
      showRppSelect: {
        type: Boolean,
        default: false
      },
      selectedRows: {
        type: [Object, Array],
        default: () => []
      },
      exportMode: {
        type: String,
        default: null
      },
      exportName: {
        type: String,
        default: null
      },
      customFields: {
        type: Array,
        default: null
      },
      additionalFields: {
        type: Array,
        default: () => []
      },
      customParams: {
        type: Object,
        default: () => {}
      },
      customFilters: {
        type: Array,
        default: null
      },
      showFilters: {
        type: Boolean,
        default: true
      },
      sortStoreName: {
        type: String,
        default: null
      },
      failed: {
        type: Boolean,
        default: false
      },
      allowMassLeadUpdate: {
        type: Boolean,
        default: true
      },
      forceRejectReasonFilter: {
        type: Boolean,
        default: false
      },
      allowBatchFilters: {
        type: Boolean,
        default: true
      },
      defaultFilters: {
        type: Object,
        default: () => {}
      },
      defaultSortProp: {
        type: String,
        default: 'created_at'
      },
      showPaginationOptions: {
        type: Boolean,
        default: false
      },
      paginationValues: {
        type: Array,
        default: () => [20, 50, 100, 200, 500]
      },
    },
    data() {
      return {
        campaign: null,
        current_search: '',
        downloading: false,
        rowsSelected: this.selectedRows,
        ready: false,
        company_users: [],
        show_mass_update_confirm: false,
        mass_update_rules: {
          email: [
            { type: 'email', message: 'Please enter a valid email address' }
          ]
        },
        mass_update_type: null,
        mass_update_contact: [],
        fields: [
          {
            title: '',
            width: '20px',
            formatter: () => '',
            titleClass: (row) => {
              switch (row.heat_rating) {
                case 'hot': return 'bg-red-600';
                case 'warm': return 'bg-yellow-600';
                case 'cold': return 'bg-blue-600';
                default: return null;
              }
            }
          },
          {
            title: 'ID',
            name: 'id',
            width: '150px'
          },
          {
            title: 'V1 ID',
            name: 'legacy_id',
            sortField: 'legacy_id',
            formatter: (val) => !val ? '-' : ( val + '' )
          },
          {
            title: 'Created',
            name: 'created_at',
            sortField: 'created_at',
            dateField: true
          },
          {
            title: 'URL',
            name: 'url-slot',
            sortField: 'from_url',
            fieldName: 'from_url|BilledStateChain',
            width: '250px',
            breakAll: true
          },
          {
            title: 'urgent',
            name: 'urgent',
            sortField: 'urgent',
            booleanField: true,
            width: '120px'
          },
          {
            title: 'First name',
            name: 'firstname-form-slot',
            fieldName: 'contact_firstname'
          },
          {
            title: 'Last name',
            name: 'lastname-form-slot',
            fieldName: 'contact_lastname'
          },
          {
            title: 'Paypal account',
            name: 'paypal-account-slot',
            sortField: 'paypal_account',
          },
          {
            title: 'Email',
            name: 'email-form-slot',
            fieldName: 'contact_email'
          },
          {
            title: 'Failed Reason',
            name: 'failed_reason',
            width: '300px',
            sortField: 'failed_reason',
          },
          {
            title: 'status',
            name: 'status-slot',
            sortField: 'status',
          },
          {
            title: 'status',
            name: 'lead_status',
            sortField: 'lead_status',
            formatter: (val) => {
              return val ? val.replace(/_/, ' ') : val
            }
          },
          {
            title: 'Type',
            name: 'type-slot',
            sortField: 'link_type',
          },
          {
            title: 'Target',
            name: 'target-slot',
            sortField: 'site_target_id',
            fieldName: 'SiteTargetId|SiteTargetName',
          },
          {
            title: 'Acquirer',
            name: 'Acquirer',
          },
          {
            title: 'Acquired On',
            name: 'acquired_date',
            sortField: 'acquired_date',
            dateField: true
          },
          {
            title: 'Created By',
            name: 'Creator',
          },
          {
            title: 'Owner',
            name: 'Owner',
          },
          {
            title: 'TF',
            name: 'trust_flow',
            sortField: 'trust_flow',
          },
          {
            title: 'CF',
            name: 'citation_flow',
            sortField: 'citation_flow',
          },
          {
            title: 'TF/CF',
            name: 'tf_cf',
            sortField: 'tf_cf',
            formatter(value) {
              return value ? value.toFixed(2) : 0
            }
          },
          {
            title: 'Ref. Domains',
            name: 'ref_domains',
            sortField: 'ref_domains',
            formatter(value) {
              return value ? value.toLocaleString() : 0
            }
          },
          {
            title: 'A Traffic score',
            name: 'ahref_traffic_score',
            sortField: 'ahref_traffic',
            formatter: (val, row) => row.ahref_traffic === row.host_ahref_traffic ? row.host_ahref_traffic : (row.ahref_traffic > row.host_ahref_traffic ? `${row.ahref_traffic} (D)` : `${row.host_ahref_traffic} (H)`)
          },
          {
            title: 'DA',
            name: 'domain_authority',
            sortField: 'domain_authority',
          },
          {
            title: 'DR',
            name: 'ahrefs_domain_rating',
            sortField: 'ahrefs_domain_rating',
          },
          /*{
            title: 'S-TS US',
            name: 'semrush_traffic_score',
            sortField: 'semrush_traffic_score',
            numberField: true
          },
          {
            title: 'S-TS UK',
            name: 'semrush_traffic_score_uk',
            sortField: 'semrush_traffic_score_uk',
            numberField: true
          },*/
          // {
          //   title: 'S-TS Global',
          //   name: 'semrush_traffic_score_global',
          //   sortField: 'semrush_traffic_score_global',
          //   numberField: true
          // },
          {
            title: '',
            name: 'actions-slot',
            titleClass: 'text-left',
            dataClass: 'text-left',
            width: this.actionsFieldWidth,
            fixed: 'right'
          },
          {
            title: 'Last Sent Email',
            name: 'last_sent_email_date',
            dateField: true,
            sortField: 'last_sent_email_date',
          },
          {
            title: 'Last Received Email',
            name: 'last_incoming_email_date',
            dateField: true,
            sortField: 'last_incoming_email_date',
          },
          {
            title: 'Last Updated',
            name: 'updated_at',
            dateField: true,
            sortField: 'updated_at',
          },
        ],
        filters: [
          {
            label: 'id'
          },
          {
            label: 'V1 ID',
            name: 'legacy_id'
          },
          {
            label: 'Url'
          },
          { label: 'TF', type: 'number', max: 2 },
          { label: 'CF', type: 'number', max: 2 },
          { label: 'TF/CF', name: 'tf_cf', type: 'number', max: 2 },
          { label: 'Ref Domains', name: 'ref_domains', type: 'number', max: 2 },
          { label: 'DR', type: 'number', max: 2 },
          { label: 'DA', type: 'number', max: 2 },
          { label: 'status', type: 'select', multiple: true, options: this.$store.state.link.statuses, name: 'status' },
          { label: 'Type', type: 'select', multiple: true, options: this.$store.state.link.types },
          { label: 'Reject Reason', name: 'reject_reason', type: 'select', multiple: true, options: linkFailedReason },
          { label: 'Site', type: 'site', multiple: true },
         { label: 'Unread emails', name: 'unread_emails', type: 'select', options: [ { value: true, label: 'True' } ] },
          {
            label: 'Urgent',
            type: 'select',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]
          },
          {
            label: 'Payment Sent',
            name: 'payment_sent',
            type: 'select',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]
          },
          {
            label: 'Suspended sites',
            name: 'suspended_sites',
            type: 'select',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]
          },
          {
            label: 'Failed Reason',
            name: 'failed_reason',
            type: 'select',
            multiple: 'true',
            options: linkFailedReason
          },
          {
            label: 'Created at',
            name: 'created_at',
            type: 'date',
            dateType: 'daterange'
          },


          {
            label: 'Acquired on',
            name: 'acquired_on',
            type: 'date',
            dateType: 'daterange'
          },
          {
            label: 'Last received email',
            name: 'last_incoming_email',
            type: 'date',
            dateType: 'daterange'
          },
          {
            label: 'Last sent email',
            name: 'last_sent_email',
            type: 'date',
            dateType: 'daterange'
          },
         {
            label: 'Acquired by',
            name: 'acquired_by_id',
            type: 'user',
            multiple: true
          },

          {
            label: 'Heat',
            name: 'heat_rating',
            type: 'select',
            multiple: true,
            options: ['warm', 'hot', 'cold']
          },
          {
            type: 'user',
            multiple: true,
            label: 'Owner',
            name: 'owner_id'
          },


          {
            label: 'Project',
            type: 'project',
            multiple: true,
            name: 'project'
          },
          {
            label: 'Site target',
            type: 'site-target',
            multiple: true,
            name: 'site_target'
          },
          {
            label: 'status',
            name: 'lead_status',
            type: 'select',
            multiple: true,
            options: [
              { label: 'Approved', value: 'accepted' },
              { label: 'Rejected', value: 'declined' },
              { label: 'Deferred', value: 'deferred' },
              { label: 'Not reviewed', value: 'not_reviewed' },
              { label: 'Parked', value: 'declined_parked' }
            ]
          },
          {
            label: 'Batch ID',
            name: 'batch_id',
            type: 'select',
            multiple: true,
            validate: () => this.allowBatchFilters
          },
          {
            label: 'Batch Name',
            name: 'batch_name',
            type: 'select',
            multiple: true,
            validate: () => this.allowBatchFilters
          }
        ],
        bulkUpdateForm: {
          show: false,
          loading: false,
          form: {
            type: null,
            status: null,
            owner: null,
          },
          rules: []
        }
      }
    },
    computed: {
      getTableSaveName() {
        if(this.sortStoreName) {
          return this.sortStoreName;
        }

        if(!this.activeOnly) {
          return 'site-links'
        }

        return 'site-leads'
      },
      getMassUpdateType() {
        return this.mass_update_type === 'accepted' ? 'accept' : (this.mass_update_type === 'declined_parked' ? 'park' : (this.mass_update_type === 'declined' ? 'decline' : 'defer'));
      },
      getFields() {
        if(this.customFields && Array.isArray(this.customFields) && this.customFields.length) {
          return [].concat(...this.customFields, ...this.additionalFields)
        }
        let fields = [].concat(...this.fields, ...this.additionalFields);
        if(!this.activeOnly) {
         fields = fields.filter(f => !['Owner', 'Acquirer', 'type-slot', 'updated_at', 'status-slot','urgent', 'last_incoming_email_date', 'last_sent_email_date', 'acquired_date'].includes(f.name))
        } else {
          fields = fields.filter(f => !['lead_status', 'email-form-slot', 'firstname-form-slot', 'lastname-form-slot'].includes(f.name))
        }

        if(!this.failed) {
          fields = fields.filter(f => f.name !== 'failed_reason')
        } else {
          fields = fields.filter(f => !['urgent', 'status', 'lead_status', 'target-slot'].includes(f.name))
        }

        if(this.noActions) {
          fields.splice(-1, 1);
        }

        return fields
      },
      shouldAllowMultipleSelect() {
        return this.allowSelection || this.allowMassUpdate
      },
      getFilters() {
        if(!this.showFilters) {
          return []
        }

        if(this.customFilters ) {
          return this.customFilters;
        }
        return this.filters.filter(f => {

          if (this.$route.params.siteId && f.type === 'site') {
            return false;
          }

          if (this.activeOnly && ['reject_reason', 'lead_status'].includes(f.name)) {
            return f.name === 'reject_reason' && this.forceRejectReasonFilter;
          }

          if (!this.activeOnly && ['status', 'last_sent_email', 'last_incoming_email', 'acquired_on'].includes(f.name)) {
            return false;
          }

          if(!this.failed && ['failed_reason'].includes(f.name)) {
            return false
          }

          if (this.$route.params.batchId  && ['batch_id', 'batch_name'].includes(f.name)) {
            return false;
          }

          return true;
        })
      },
      getParams() {
        return Object.assign({}, {
          active_leads_only: this.activeOnly,
          batch_id: this.$route.params.batchId,
          campaign_id: this.$route.params.campaignId,
          site_id: this.$route.params.siteId,
          client_id: this.$route.params.clientId,
          status: this.status || undefined,
          is_billed: this.isBilled,
          search: this.current_search,
          billing_status: this.billingStatus,
          include_exchange_prices: this.includeExchangePrices,
          include_billed_state_change: this.includeBillingHistory,
          failed: this.failed,
        }, this.customParams)
      }
    },
    mounted() {
      this.getResources();
    },
    methods: {
      onActiveFiltersChange(val){
        this.$emit('active-filter-change', val)
      },
      getCustomSlots() {
        return this.$scopedSlots ? this.$scopedSlots : []
      },
      bulkUpdateLeads() {
        if(this.bulkUpdateForm.form.type || this.bulkUpdateForm.form.status || this.bulkUpdateForm.form.owner) {
          this.bulkUpdateForm.loading = true;
          const body = {...this.bulkUpdateForm.form, ...{ filter_params: this.$refs.table.getFilterData() }}
          this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, body).then((res) => {
            this.$refs.table.getData()
            this.bulkUpdateForm.show = false
            this.bulkUpdateForm.form.type = null
            this.bulkUpdateForm.form.status = null
            this.bulkUpdateForm.form.owner = null
          }).catch(e => {
            console.error('The error: ' + e)
            this.$message.error('Failed to bulk update the items')
          })
          .finally(() => {
            this.bulkUpdateForm.loading = false
          })
        }
      },
      stripDomainFromUrl(v) {
        if(typeof v === 'string') {
          try {
            const p = new URL(v).pathname
            return p && p !== '/' ? p : v;
          } catch (e) {}
        }
        return v;
      },
      download() {
        const params = Object.assign({}, this.getParams, {
          export_mode: this.exportMode,
          filter_params: this.$refs.table.getFilterData()
        });
        const url = `/v1/companies/${this.$store.state.company.company.id}/links`;
        this.downloading = true
        this.$http.get(url, { params, responseType: 'blob' })
          .then(r => {
            const name = (new Date()).getTime() + '_' + (this.exportName ||  this.exportMode + ' LINKS REPORT') + '.csv'
            FileDownload(r.data, name)
          })
          .catch(() => {
            this.$message.error('An error occurred attempting to export that report.')
          })
          .finally(() => {
            this.downloading = false
          })
      },
      getResources() {
        const resourceList = [];
        const filters = this.getFilters;
        const batchIdFilter = filters.find(f => f.name === 'batch_id')
        const hasBatchIdFilter = batchIdFilter && (typeof batchIdFilter.validate === 'function' ? batchIdFilter.validate() : true)
        const BatchNameFilter = filters.find(f => f.name === 'batch_name')
        const hasBatchNameFilter = BatchNameFilter && (typeof BatchNameFilter.validate === 'function' ? BatchNameFilter.validate() : true)
        if (!this.$route.params.batchId && (hasBatchIdFilter || hasBatchNameFilter)) {
          resourceList.push({
            request: this.$http.get(`/v1/companies/${this.$store.state.company.company.id}/batches`, {
              params: {
                all: false,
                include_reviewed_status: false,
                req_fields: ['id', 'name']
              }
            }),
            action: (response) => {
              const batches = response.data.data

              const batchId = this.filters.find(f => f.name === 'batch_id')
              if(batchId) {
                batchId.options = batches.map(r => {
                  return {
                    value: r.id,
                    label: r.id
                  }
                })
              }

              const batchName = this.filters.find(f => f.name === 'batch_name')
              if(batchName) {
                batchName.options = batches.map(r => {
                  return {
                    value: r.id,
                    label: r.name
                  }
                })
              }
            }
          })
        }

        if (this.activeOnly && this.allowMassLeadUpdate) {
          this.$store.dispatch('users/loadAll', {}).then(r => {
            this.company_users = r
          })
        }

        if(!resourceList.length) {
          this.ready = true;
        } else {
          const allRequests = resourceList.map(r => r.request)
          this.$http.all(allRequests).then(this.$http.spread((...responses) => {
            for(let i = 0; i < allRequests.length; i++) {
              resourceList[i].action(responses[i])
            }
            this.ready = true;
          }))
        }
      },
      setUpMassUpdate(type) {
        if(this.rowsSelected.length) {
          this.mass_update_type = type;

          this.mass_update_contact = [];
          if(this.mass_update_type === 'accepted') {
            for (let i = 0; i < this.rowsSelected.length; i++) {
              this.mass_update_contact.push({
                firstname: this.rowsSelected[i].contact_firstname,
                lastname: this.rowsSelected[i].contact_lastname,
                email: this.rowsSelected[i].contact_email,
                link_id: this.rowsSelected[i].id
              })
            }
            this.show_mass_update_confirm = true;
          } else {
            this.massUpdate()
          }
          //this.resetContactForm();
        }
      },
      resetContactForm() {
        this.mass_update_contact.firstname = null;
        this.mass_update_contact.lastname = null;
        this.mass_update_contact.email = null;
      },
      async massUpdate() {

        if(this.mass_update_type === 'accepted' && this.$refs.mass_update_contact_form) {
          const forms = Array.isArray(this.$refs.mass_update_contact_form) ? this.$refs.mass_update_contact_form : [this.$refs.mass_update_contact_form]
          for (const form of forms) {
            try {
              if (!(await form.validate())) {
                return;
              }
            } catch (e) {
              return;
            }
          }
        }

        const ids = this.rowsSelected.map(i => i.id)
        const params = {
          ids,
          lead_status: this.mass_update_type,
        }

        if (this.mass_update_type === 'accepted' && this.mass_update_contact.length) {
          // check if we have the form ID
          this.mass_update_contact.forEach(contact => {
            if(contact.email && contact.email !== '') {
              params[contact.link_id + '_firstname'] = contact.firstname;
              params[contact.link_id + '_lastname'] = contact.lastname;
              params[contact.link_id + '_email'] = contact.email;
            }
          });
        }

        this.downloading = true;
        this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/links/mass-update`, params)
        .then(() => {
          this.$message.success(`Successfully updated ${this.rowsSelected.length} links.`)
          this.$emit('on-mass-update');
          this.$refs.table.clearSelection();
          this.show_mass_update_confirm = false;
          this.refresh();
        })
        .catch(() => {
          this.$message.error('Failed to update the links. Please try again later.')
        })
        .finally(() => {
          this.downloading = false;
        })
      },
      refresh() {
        this.$refs.table.getData()
      },
      onLoad(v) {
        this.$emit('on-load', v)
      }
    },
    watch: {
      getFilters(){
        this.$emit('filters-change', this.getFilters)
      },
      rowsSelected: function(val) {
        this.$emit('update:selected-rows', val)
      }
    }
  }
</script>
