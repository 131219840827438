<template>
  <div v-loading="loading" class="h-full">
    <div class="page-title">
      <h1>
        Content
      </h1>
    </div>
    <trieste-table
      :end-point="`/v1/companies/${$store.state.company.company.id}/content`"
      :fields="fields"
      title="Content"
      item-type="content items"
      :show-title="false"
      ref="table"
      :params="{ attachable_site_link: $route.params.linkId }"
      v-if="ready"
      @on-load="loading = false"
      :filters="tableFilters"
      saved-table-sort-name="content-index"
      :default-filter-data="defaultFilters"
      default-sort-prop="created_at"
      default-sort-direction="descending"
      :min-column-width="90"
      cache-table-filters
    >
      <template v-slot:header-right>
     <!--   <el-button type="primary" size="medium" icon="el-icon-edit-outline" @click="show_create_content_dialog = true"
                   v-if="!attaching_link && $store.getters['auth/hasPermission']('content.create')">Create
        </el-button> -->
      </template>
      <template v-slot:id="props">
        <a :href="`/company/${$store.state.company.company.id}/content/${props.row.id}`" class="font-bold">
          {{ props.row.id }}
        </a>
      </template>
      <template v-slot:title-slot="props">
        <!-- <a :href="`/company/${$route.params.companyId}/content/${props.row.id}`">
        </a> -->
        <span>{{ props.row[props.rowField.field] || '{untitled}' }}</span>
      </template>
      <template v-slot:value-slot="props">
        <span
          :class="{'text-gray-400': !props.row[props.rowField.field]}">{{ props.row[props.rowField.field] || 'N/A' }}</span>
      </template>
      <template v-slot:owner-slot="props">
        <div>
          <div v-if="!$store.getters['auth/hasPermission']('other.content.status.update')">
            <div v-if="props.row.owner">
              {{ `${props.row.owner.firstname} ${props.row.owner.lastname}` }}
            </div>
            <div v-else>
              <span class="text-xs">not assigned.</span>
            </div>
          </div>
          <div v-else>
            <el-select size="mini" v-model="props.row.owner_id" @change="updateOwnerId(props.row)" collapse-tags filterable>
              <el-option :value="null" label="none" />
              <el-option v-for="(cu, i) in users" :key="i" :value="cu.id" :label="`${cu.firstname} ${cu.lastname}`" />
            </el-select>
          </div>
        </div>
      </template>
      <template v-slot:sitelink-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/leads/${props.row.site_link.id}`"
            v-if="props.row.site_link">
            <div class="flex flex-row items-center font-bold">
              <div class="mr-1" v-if="false">
                <logo-widget v-model="props.row.site_link.domain" :size="12"/>
              </div>
              {{ props.row.site_link.from_url }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:sitelink-id-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/leads/${props.row.site_link.id}`"
            v-if="props.row.site_link">
            <div class="flex flex-row items-center font-bold">
              {{ props.row.site_link.id }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:sitelink-status-slot="props">
        <div>
          <a
            :href="`/company/${$route.params.companyId}/leads/${props.row.site_link.id}`"
            v-if="props.row.site_link">
            <div class="flex flex-row items-center font-bold">
              {{ props.row.site_link.status.replace(/_/g, ' ') }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:target-slot="props">
        <div>
          <div v-if="props.row.site_target" class="flex flex-row items-center font-bold">
            <a :href="`/company/${$route.params.companyId}/targets/${props.row.site_target.id}`">
              {{ props.row.site_target.name }}
            </a>
          </div>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:client-slot="props">
        <div>
          <div v-if="props.row.site_target && props.row.site_target.site && props.row.site_target.site.client"
               class="flex flex-row items-center font-bold">
            <a :href="`/company/${$route.params.companyId}/clients/${props.row.site_target.site.client.id}`">
              {{ props.row.site_target.site.client.name }}
            </a>
          </div>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:site-slot="props">
        <div>
          <a :href="`/company/${$route.params.companyId}/sites/${props.row.site_target.site.id}`"
             v-if="props.row.site_target && props.row.site_target.site">
            <div class="flex flex-row items-center font-bold">
              <div class="mr-1" v-if="false">
                <logo-widget v-model="props.row.site_target.site.domain.base_url" :size="12"/>
              </div>
              {{ props.row.site_target.site.domain }}
            </div>
          </a>
          <div v-else>
            <span class="text-gray-400">N/A</span>
          </div>
        </div>
      </template>
      <template v-slot:date-slot="props">
        {{ props.row.created_at | momentLocal("ddd MMMM DD YYYY Z") }}<br/>
        <span class="text-xs">
                      {{ props.row.created_at | moment("from", "now") }}
                    </span>
      </template>
      <template v-slot:updated-slot="props">
        {{ props.row.updated_at | momentLocal("ddd MMMM DD YYYY Z") }}<br/>
        <span class="text-xs">
                      {{ props.row.updated_at | moment("from", "now") }}
                    </span>
      </template>
      <template v-slot:actions-slot="props"
                v-if="attaching_link && $store.getters['auth/hasPermission']('link.update')">
        <a :href="`/company/${$route.params.companyId}/content/${props.row.id}`"
           v-if="!attaching_link && $store.getters['auth/hasPermission']('content.read') && false">
          <el-button
            size="small"
            icon="el-icon-edit-outline"
            type="primary">
            Edit
          </el-button>
        </a>
        <el-button
          size="small"
          icon="el-icon-view"
          @click="attachSiteLink(props.row)"
          type="success">
          Attach
        </el-button>
      </template>
    </trieste-table>
    <logo-widget v-if="false"/>
    <request-content-widget :show.sync="show_create_content_dialog" @success="refreshTable"
                            v-if="$store.getters['auth/hasPermission']('content.create')"/>
  </div>
</template>
<script>
import LogoWidget from "../../../components/incs/LogoWidget";
import RequestContentWidget from "../../../components/incs/RequestContentWidget";
import { formatResponseErrorMessage } from "@/utils/helpers";

export default {
  components: {RequestContentWidget, LogoWidget},
  metaInfo() {
    return {
      title: () => `${this.$store.state.company.company.name} content`,
    }
  },
  data: function () {
    return {
      defaultFilters: {},
      loading: false,
      query: {
        rpp: 20,
        page: 1
      },
      users: [],
      attaching_link: false,
      fields: [
        {
          title: 'ID',
          name: 'id',
          sortField: 'id',
          formatter: (val) => val + ''
        },
        {
          title: 'V1 ID',
          name: 'legacy_id',
          sortField: 'legacy_id',
          formatter: (val) => !val ? '-' : ( val + '' )
        },
        {
          name: 'title-slot',
          title: 'title',
          field: 'title',
          sortField: 'title',
          width: '300px'
        },
        {
          name: 'owner-slot',
          title: 'Owner',
          sortField: 'owner.firstname'
        },
        {
          name: 'urgent',
          sortField: 'urgent',
          title: 'urgent',
          booleanField: true
        },
        /*{
          name: 'sitelink-slot',
          title: 'Site link',
        },*/
        {
          name: 'sitelink-id-slot',
          title: 'Site link',
          breakAll: true
        },
        {
          name: 'sitelink-status-slot',
          title: 'Site link status',
        },
        {
          name: 'target-slot',
          title: 'Target',
          size: 'small',
          sortField: 'site_target.name'
        },
        {
          name: 'site-slot',
          title: 'Site',
          size: 'small',
          sortField: 'site_target.site.domain',
        },
        {
          name: 'client-slot',
          title: 'Client',
          size: 'small',
          sortField: 'site_target.site.client.name'
        },
        {
          name: 'status',
          title: 'status',
          sortField: 'status',
          formatter: (val) => {
            if (val) {
              return val.replace(/_/g, ' ')
            }
            return '<span class="text-gray-400">N/A</span>'
          }
        },
        {
          name: 'item_type',
          sortField: 'item_type',
          title: 'Type',
          formatter: (val) => {
            if (val) {
              return val.replace(/_/g, ' ')
            }
            return '<span class="text-gray-400">N/A</span>'
          }
        },
        {
          name: 'creator',
          title: 'Creator',
          sortField: 'creator.firstname',
          formatter: val => val ? `${val.firstname} ${val.lastname}` : 'N/A'
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          title: 'Created at',
          dateField: true,
        },
        {
          name: 'updated_at',
          sortField: 'updated_at',
          title: 'Updated at',
          dateField: true,
        },
        {
          name: 'published_at',
          sortField: 'published_at',
          title: 'Published at',
          dateField: true,
        },
        {
          title: '',
          name: 'actions-slot',
          fixed: 'right',
          width: '120px',
          check_slot: true
        },
      ],
      tableFilters: [
        {
          label: 'Title'
        },
        {
          label: 'ID'
        },
        {
          label: 'V1 ID',
          name: 'legacy_id'
        },
        {
          label: 'Body'
        },
        {
          label: 'status',
          type: 'select',
          multiple: true,
          options: [
            {
              value: 'assigned',
              label: 'Assigned'
            },
            {
              value: 'cancelled',
              label: 'Cancelled'
            },
            {
              value: 'draft',
              label: 'Draft'
            },
            {
              value: 'published',
              label: 'Published'
            },
            /*{
              value: 'waiting_for_tier_2_approval',
              label: 'Waiting For Tier 2 Approval'
            },*/
            {
              value: 'new',
              label: 'New'
            },
            /*{
              value: 'waiting_for_tier_1_approval',
              label: 'Waiting For Tier 1 Approval'
            },*/
            {
              value: 'awaiting_client_approval',
              label: 'Awaiting Client Approval'
            },
            /*{
              value: 'pending',
              label: 'Pending'
            },*/
            {
              label: 'Editor Review',
              value: 'editor_review'
            },
            {
              label: 'On Hold',
              value: 'on_hold'
            },
            /*{
              label: 'Revision',
              value: 'revision'
            },*/
            {
              label: 'Returned',
              value: 'returned'
            },
            {
              label: 'Client approved',
              value: 'client_approved'
            },
            {
              label: 'Client declined',
              value: 'client_declined'
            }
          ]
        },
        {
          label: 'Type',
          type: 'select',
          multiple: true,
          options: [
            {label: 'title and content', value: 'title_and_content'},
            {label: 'title only', value: 'title_only'},
            {label: 'content only', value: 'content_only'},
          ]
        },
        {
          label: 'urgent',
          type: 'select',
          multiple: true,
          options: [
            {label: 'Yes', value: 1},
            {label: 'No', value: 0},
          ]
        },
        {
          label: 'Creator',
          name: 'created_by_id',
          multiple: true,
          type: 'select'
        },
        {
          label: 'Site',
          type: 'site',
          multiple: true,
          name: 'site_id'
        },
        {
          label: 'Project',
          type: 'project',
          multiple: true,
          name: 'project_id'
        },
        {
          label: 'Site target',
          type: 'site-target',
          multiple: true,
          name: 'site_target_id'
        },
        {
          label: 'Site link',
          name: 'site_link',
        },
        {
          label: 'Site link status',
          name: 'site_link_status',
          type: 'select',
          multiple: true,
          options: this.$store.state.link.statuses
        },
        {
          label: 'Content available',
          name: 'content_available',
          type: 'select',
          options: [
            {
              value: true,
              label: 'Yes'
            },
            {
              value: false,
              label: 'No'
            }
          ]
        },
        {
          label: 'Owner',
          name: 'owner_id',
          multiple: true,
          type: 'select',
          options: []
        },
        {
          label: 'Created at',
          name: 'created_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Published at',
          name: 'published_at',
          type: 'date',
          dateType: 'daterange'
        },
        {
          label: 'Lead type',
          name: 'lead_type',
          type: 'select',
          multiple: true,
          options: this.$store.state.link.types
        },
      ],
      show_create_content_dialog: false,
      ready: false
    }
  },
  mounted() {
    if (!this.$store.getters['auth/hasPermission']('content.read')) {
      this.$message.error('You do not have permissions to view content items.');
      this.$router.push('/');
    }

    this.$store.dispatch('company/getCompany', this.$route.params).then((company) => {
      this.$store.commit('global/setBreadcrumbs', [
        {
          path: '/',
          label: company.name
        },
        {
          path: `/content`,
          label: 'Content'
        }
      ])
    })

    if (this.$route.params.linkId) {
      this.attaching_link = true;
    }

    this.loadUsers()
  },
  methods: {
    loadUsers() {
      this.loading = true;
      this.$store.dispatch('users/loadAll', {})
        .then(r => {
          this.users = r
          const filters = this.tableFilters.filter(t => ['created_by_id', 'owner_id'].includes(t.name))
          for (const f of filters) {
            f.options = this.users.map(u => {
              return {label: `${u.firstname} ${u.lastname}`, value: u.id}
            })
          }
          this.ready = true
        })
        .catch(() => {
          this.$message.error('Failed to get company users')
        })
    },
    attachSiteLink(item) {
      item.site_link_id = this.$route.params.linkId
      this.$http.post(`/v1/companies/${this.$store.state.company.company.id}/content/${item.id}/attach`, {
        site_link_id: this.$route.params.linkId
      })
        .then(r => {
          this.$message.success('Successfully attached the content item.')
          const link = r.data.link;
          setTimeout(() => {
            const route = `/company/${this.$store.state.company.company.id}/leads/${this.$route.params.linkId}`;
            this.$router.push(route)
          }, 800)
        }).catch(() => {
        this.$message.error('An error occurred attaching the content item.')
      })
    },
    refreshTable() {
      this.loading = true;
      this.$refs.table.getData()
    },
    updateOwnerId(item) {
      this.$http.put(`/v1/companies/${this.$store.state.company.company.id}/content/${item.id}`, item)
        .then(r => {
          this.$message.success('Successfully updated the owner.');
          // this.$refs.table.getData()
        })
        .catch((err) => {
          let message = formatResponseErrorMessage(err)
          this.$message.error(message)
          console.error(err.toJSON(), message)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    resetFields() {
      this.search_params = {
        created_by_id: [],
        owner_id: [],
        site_target_id: [],
        site_id: [],
        status: [],
        type: [],
      }
      this.refreshTable()
    }
  }
}
</script>
